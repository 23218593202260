import styled from "styled-components";

export const Styles = styled.div`
    .group-page {
        .icon-box-area {
            padding-bottom : 72px;
            .full-icon-box {
                .icon-box {
                    position: inherit;
                    top: 0;
                    left: 0;
                    width: unset;
                }
            }

            @media(max-width: 767px) {
                padding: 0 0 10px;
            }
        }
        
    }
    .readmore-btn {
        display:none !important
    }
    .about-para {
        text-align:justify
    }
    .back1 {
        background: #fff;
    border: 1px solid #10b477;
    }
    .para {
        font-size: 15px;
    line-height: 25px;
    margin-bottom: 10px;
    text-align: justify;
    }
`;