import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class Registrationdetails extends Component {
    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Registrations</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Advance Registration</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Our Ranking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8" className='back1'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Registrations</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Admissions are made on the basis of priority of registration and number of seats available in each class.</li>
                                                <li><i className="fa fa-check"></i>Parents may come to Shastry school office and get the Prospectus by paying the registration fee. </li>
                                                <li><i className="fa fa-check"></i>Read the prospectus carefully. For any clarifications contact Mrs. Mary Margaret personally or contact over phone .Phone Number: 8050169541</li>
                                                <li><i className="fa fa-check"></i>Submit the filled application along with essential documents.</li>
                                                <li><i className="fa fa-check"></i>Please remember, as registration carries no guarantee of admission</li>
                                                <li><i className="fa fa-check"></i>Parents are advised to ascertain the admission only after detailed verification of the documents and application form, from the office.</li>
                                                <li><i className="fa fa-check"></i>In the case of admission for kids from PP1 to Class1, indisputable evidences with regards to child’s age such as birth registration certificate from the competent authority, is must.</li>
                                                <li><i className="fa fa-check"></i>In the case of lateral admissions from Class2 to Class8, proper evidences such as mark card duly signed by the Head of the previous school from where the students have come from, character and conduct certificate and transfer certificate, need to be submitted along with the application. Only after verifying all the certificates and if seats are available in that class, based on performance on the Shastry school entrance test and interviews, seat will be confirmed.</li>
                                                <li><i className="fa fa-check"></i>Once when the seat is confirmed then immediately stipulated fee should be paid (see appendix). </li>
                                                <li><i className="fa fa-check"></i>Confirmed seat may be forfeited if the fee is not paid before the deadline given to parents.</li>
                                                <li><i className="fa fa-check"></i>Those who would like to join PU course need to submit TC, mark card issued by State Board/CBSE Board/ICSE or any other equivalent Board.</li>
                                                
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Advance Registration</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>The products of Shastry Schools have become Nation building sculptors. Most of them are working in the reputed institutions of the country and abroad.</li>
                                                <li><i className="fa fa-check"></i>Many students of Shastry Schools performed well in AIEEE or JEE, CET, NEET, GRA, TOEFL, etc., From the past many years, they are grabbing seats in reputed Engineering Colleges and Medical Colleges. Most of them working as doctors, engineers, technicians, lawyers,etc., </li>
                                                <li><i className="fa fa-check"></i>Ever since the inception, Shastry schools enjoying outstanding results and distinctions. So far it has produced 26 successful batches of SSLC students.</li>
                                                <li><i className="fa fa-check"></i>Pass percentage of students either at the State Board of at yheCentral Board of Secondary Education Level is 90 to 99 percent with many distinctions.</li>
                                                <li><i className="fa fa-check"></i>Pass percentage of  PU students is also 90 to 99 percent with many distinctions</li>
                                                <li><i className="fa fa-check"></i>Shastry school students participated ,showcasedtheir talents  and won prizes in International, National, State and Districts levels.</li>
                                                <li><i className="fa fa-check"></i>Students of Shastryschool participated even at the National Level Sport competitions. They won prizes at the School level, Taluk level, District level, State level and Zonal level, several timesand for several years.</li>
                                                <li><i className="fa fa-check"></i>School encourages march past teams and band set teams. Students in these teams got award as the best march past teams at the National level, during Independence Day Republic Day events.</li>
                                                <li><i className="fa fa-check"></i>Scouts attended International Cultural Jamboorie, in Mudubidre.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Near Future Plans </h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Those who wish to register their names before the annual examination/Public examinations/before receiving mark sheets and certificate, such students can also obtained the prospectus from the school office and submit their application in advance without the essential documents. Preference will be given to such candidates only after fulfilling the required procedure for admission. In any case registration fee is not refundable. </li>
                                                <li><i className="fa fa-check"></i>If children/students, withdrawn from the school at any time, subsequent to their admission / re-admission then there will be a charged fee for the total academic year except when the withdrawn is effected by the commencement of classes, in which case, they need to pay half of the academic year fee, only.</li>
                                                <li><i className="fa fa-check"></i>If fees are not paid within 15 days of the stipulated dates mentioned earlier then a fine of 5% per month will be charged on the amount of fee to be cleared.</li>
                                                
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">Our Ranking</h4>
                                            <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="research">
                                            <h4 className="tab-title">Our Research</h4>
                                            <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default Registrationdetails
