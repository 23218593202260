import React, { Component } from 'react';
import Datas from '../data/punchmuka/punchmuka.json';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Styles } from "./styles/iconBox.js";

class Punchmuka extends Component {
    render() {
        return (
            <Styles>
                {/* Icon Box */}
                <section className="icon-box-area">
                    <Container>
                        <br/>
                        <br/>
                        <Row>
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th className='text-center' colSpan={3}><h5>Through multi-sensory organs and multi perceptory organs based on TaittireeyaUpanishat</h5></th>
                                    </tr>
                                    <tr>
                                        <th className='text-center'>Sl.No</th>
                                        <th className='text-center'>Upanishat name</th>
                                        <th className='text-center'>Development</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                Datas.punchmukaList.map((data, i) => (
                                    <tr  key={i}>
                                        <td className='text-center'>{data.id}</td>
                                        <td className='text-center'>{data.name}</td>
                                        <td className='text-center'>{data.development}</td>
                                    </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className='text-center' colSpan={3}><h5>Competitive Examinations:</h5></th>
                                    </tr>
                                    <tr>
                                        <th className='text-center'>Sl.No</th>
                                        <th className='text-center'>Examinations name</th>
                                        <th className='text-center'>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                Datas.Exams.map((data, i) => (
                                    <tr  key={i}>
                                        <td className='text-center'>{data.id}</td>
                                        <td className='text-center'>{data.name}</td>
                                        <td className='text-center'>{data.development}</td>
                                    </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default Punchmuka
