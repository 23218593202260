import React, { Component } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Downloads from '../../components/Download';
import Footer from '../../components/Footer';
import { Styles } from "./styles/download.js";

class Download extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper download-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Download" />

                    {/* Tab Section */}
                    <Downloads />

                    {/* Footer 2 */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default Download