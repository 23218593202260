import styled from "styled-components";

export const Styles = styled.div`
    .digital-page {
        .gallery-page-area {
            padding : 70px 0;
            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                text-align: center;
                border: 1px solid #0fb172;
                padding: 10px;
                img {
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                    height:200px;
                }
            }

            @media(max-width: 767px) {
                padding: 30px 0;
            }
        }
    }
`;