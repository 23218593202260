import React, { Component } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import AboutUs from '../../components/AboutUs';
import IconBox from '../../components/IconBox';
import Practicedetails from '../../components/Practicedetails';
import TestimonialSlider from '../../components/TestimonialSlider';
import FaqEvent from '../../components/FaqEvent';
import Footer from '../../components/Footer';
import { Styles } from "./styles/practice";

class Practice extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper practice-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Best Practice" />

                    {/* About Area */}
                    {/* <AboutUs /> */}

                    {/* Icon Box Area */}
                    {/* <IconBox /> */}

                    {/* Tab Section */}
                    <Practicedetails />

                    {/* Testimonial Slider */}
                    {/* <TestimonialSlider /> */}

                    {/* Faq & Event Area */}
                    {/* <FaqEvent /> */}

                    {/* Footer 2 */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default Practice