import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown,Button } from 'react-bootstrap';
import Search from './common/Search';
import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";

class HeaderTwo extends Component {
    render() {
        return (
            <Styles>
                {/* Topbar 2 */}
                <section className="top-bar2">
                    <Container>
                         <div id="mybutton">
                            <Button className="admin" style={{ marginLeft: "auto" }}>
                            <a href="https://wa.me/918050169541" style={{ color: "#fff" }}><i className="lab la-whatsapp"></i> Whatsapp</a>
                            </Button>
                            <br/>
                            <br/>
                            <Button className="admin" style={{ marginLeft: "auto" }}>
                               <Link  style={{ color: "#fff" }} to={process.env.PUBLIC_URL + "/contact"}> Admissions Open </Link>
                            </Button>
                        </div>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        {/* <li className="list-inline-item"><i className="las la-phone"></i>+1 (396) 486 4709</li>
                                        <li className="list-inline-item"><i className="las la-envelope"></i>enquery@domain.com</li> */}
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>Shastry Public School,B.M Bye pass road,Hunsur-571105
                                    </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="5" md="3">
                                <div className="bar-right d-flex justify-content-end">
                                    {/* <ul className="list-unstyled list-inline bar-lang">
                                        <li className="list-inline-item">
                                            <Dropdown>
                                                <Dropdown.Toggle><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" />English<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                <Dropdown.Menu as="ul">
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" /> English</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/fra.png"} alt="" /> French</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/ger.png"} alt="" /> German</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/spa.png"} alt="" /> Spanish</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" /> Brazilian</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul> */}
                                    <ul className="list-unstyled list-inline bar-social">
                                        <li className="list-inline-item"><a href="https://www.facebook.com/shastryschool?mibextid=ZbWKwL" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href="https://youtu.be/oQLM9D-LLbQ"><i className="fab fa-youtube" target="_blank"></i></a></li>
                                        <li className="list-inline-item"><a href="https://instagram.com/shastryschool?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>

                                    <ul className="list-unstyled list-inline sidebar-button">
                                        <li className="list-inline-item nav-item side-box">
                                            <Sidebar />
                                        </li>
                                        <li className="list-inline-item nav-item side-box"><Search /></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    <Container>
                        <Row>
                            <Col md="1">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="11">
                                <div className="menu-box d-flex justify-content-end">
                                    <ul className="nav menu-nav">
                                    <li className="nav-item dropdown active">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Home </Link>
                                                {/* <ul className="dropdown list-unstyled">
                                                    <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                                                </ul> */}
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/about"}>About</Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/trusts"}>Trust</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/groups"}>Group of School</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/motos"}>Motto  </Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/punchamukha"}>Panchamuka Shikashana</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/practice"}>Best Practice </Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/facilities"}>Facilities </Link></li>
                                            <li className="nav-item dropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/activities"}>Activities </Link></li>
                                            <li className="nav-item dropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/registrations"}>Registration</Link></li>
                                            <li className="nav-item dropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                            <li className="nav-item dropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/downloads"}>Download</Link></li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/contact"} data-toggle="dropdown">Locate us <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Alumni</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/digital-partner"}>Digital Partner  </Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown"><Link className="nav-link" to={process.env.PUBLIC_URL + "/topper"}>Toppers </Link></li>
                                    </ul>
                                    <div className="apply-btn">
                                        <Link to={process.env.PUBLIC_URL + "/admissions"}><i className="las la-clipboard-list"></i>Admission</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default HeaderTwo
