import React, { Component } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import AboutUs from '../../components/AboutUs';
import Admindetails from '../../components/Admindetails';
import Footer from '../../components/Footer';
import { Styles } from "./styles/admission.js";

class Admission extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper admission-page">

                    {/* Header 2 */}
                    <HeaderTwo />
                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Admission"/>
                    {/* About Area */}
                    {/* <AboutUs /> */}
                    {/* Faq & Event Area */}
                    <Admindetails />
                    {/* Footer 2 */}
                    <Footer/>
                </div>
            </Styles>
        )
    }
}

export default Admission