import React, { useEffect } from 'react';
import Datas from '../data/groups/groups.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/courseFilter.js";

function Groupsdetails() {
    useEffect(() => {
        const buttons = document.querySelector(".filter-btn-list").children;
        const items = document.querySelector(".filter-items").children;

        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener("click", function () {

                for (let j = 0; j < buttons.length; j++) {
                    buttons[j].classList.remove("active");
                }

                this.classList.add("active");
                const target = this.getAttribute("data-target");

                for (let k = 0; k < items.length; k++) {
                    items[k].style.display = "none";

                    if (items[k].getAttribute("data-id") === target) {
                        items[k].style.display = "block";
                    }

                    if (target === "*") {
                        items[k].style.display = "block";
                    }
                }
            })
        }
    });

    return (
        <Styles>
            {/* Course Area */}
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{Datas.secTitle}</h4>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="filter-btns text-center">
                                <ul className="filter-btn-list list-unstyled list inline">
                                    {/* <li data-target="*" className="active list-inline-item">All Courses</li>
                                    <li data-target="desi" className="list-inline-item">CBSE BOARD</li>
                                    <li data-target="deve" className="list-inline-item">STATE BOARD</li>
                                    <li data-target="seo" className="list-inline-item">PU COLLEGE (PCMB/PCMC)</li> */}
                                </ul>
                            </div>
                            <Row className="filter-items">
                                {
                                    Datas.dataList.map((data, i) => (
                                        <Col lg="4" md="6" key={i} data-id={data.targetId}>
                                            <div className="course-item back1">
                                                <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                    <div className="course-image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})`}}>
                                                        <div className="author-img d-flex">
                                                            <div className="img">
                                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                                            </div>
                                                            <div className="title">
                                                                <p>{data.authorName}</p>
                                                                <span>{data.authorCourses}</span>
                                                            </div>
                                                        </div>
                                                        <div className="course-price">
                                                            <p>{data.price}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="course-content">
                                                    <h6 className="heading"><Link to={process.env.PUBLIC_URL + data.courseLink}>{data.courseTitle}</Link><br/>{data.scool_date}</h6>
                                                    
                                                    {/* <p className="desc">{data.courseDesc}</p>
                                                    <div className="course-face d-flex justify-content-between">
                                                        <div className="duration">
                                                            <p><i className="las la-clock"></i>120</p>
                                                        </div>
                                                        <div className="rating">
                                                            <ul className="list-unstyled list-inline">
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                </li>
                                                                <li className="list-inline-item">(4.5)</li>
                                                            </ul>
                                                        </div>
                                                        <div className="student">
                                                            <p><i className="las la-chair"></i>60</p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                        <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                {/* <Link to={process.env.PUBLIC_URL + "/course-grid"}>View All Courses</Link> */}
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="title">
                                <p className='para'>All Shastry schools are affiliated either by Karnataka State Education Board (State High school Dice Code : 29260426511 and PU College Code: NN0153) or CBSE (Affiliation No: 830312), New Delhi.</p>
                                
                                <p className='para'>Modern Technology is integrated in the curriculum. Syllabi prescribed by Central Board of Secondary Education and Karnataka State Education Board are followed at the relevant stages of Education. From Pre-Primary1 to ClassVIII standard level without trespassing the very important guide lines of respective boards, SCT  utilizes its autonomy and implement the syllabi those are conducive and give excellent strength to students in literacy skills (listening, speaking, reading and writing) and numeracy skills. Along with this, the school is in consonance with the great tradition and ideals of Bharateeya culture. Also, the school lays utmost stress on character building, smart appearance, high standard of personal conduct and social conduct. Even it provides leadership along with Samskaras. Primary importance will be given to key areas such as language learning, problem solving in Mathematics and art & craft.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default Groupsdetails
