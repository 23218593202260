import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class Motos extends Component {
    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="12" md="12">
                                    <h4 className="tab-title">MOTTO</h4>
                                    <p className="tab-desc">True education For Fruition Life Through Bharateeya Samskara.</p>
                                    <p>ಭಾರತೀಯ ಸಂಸ್ಕಾರದ ಮೂಲಕ ಸಾರ್ಥಕಜೀವನಕ್ಕಾಗಿ ನೈಜ ಶಿಕ್ಷಣ</p>
                                    <h4 className="tab-title">EMBLEM (ಲಾಂಛನ)</h4>
                                    {/* <p className="tab-desc">True education For Fruition Life Through Bharateeya Samskara.</p>
                                    <p>ಭಾರತೀಯ ಸಂಸ್ಕಾರದ ಮೂಲಕ ಸಾರ್ಥಕಜೀವನಕ್ಕಾಗಿ ನೈಜ ಶಿಕ್ಷಣ</p> */}

                                    <h4 className="tab-title">Samskrita (ಸಂಸ್ಕøತ )</h4>
                                    <p className="tab-desc">Portrayed in the emblem is the concept of bipolar interlink between Samskrita and Samskriti as well as Samskriti and Education, in turn, both manifest ‘Samskara’. Intersected green foliage implies the basic source of energy for life on earth.</p>
                                    <p className="tab-desc">ಲಾಂಛನದಲ್ಲಿ ಚಿತ್ರಿಸಿರುವ ದ್ವಿಧ್ರುವಅಂತಃಕೊಂಡಿಯು, ಸಂಸ್ಕøತ ಮತ್ತು ಸಂಸ್ಕøತಿ ಹಾಗೂ ಸಂಸ್ಕøತ ಮತ್ತು ಶಿಕ್ಷಣವನ್ನು ಬೆಸೆದು, ಈ ಎರಡೂ ಸಹ ಸಂಸ್ಕಾರವನ್ನು ಪ್ರಕಟಗೊಳಿಸುತ್ತವೆ. ಅಡ್ಡಹಾಯ್ದಿರುವ ಪರ್ಣ ಗರಿಗಳು ಭೂಮಿಯ ಮೇಲಿನ ಜೀವಿಯ ಮೂಲ ಶಕ್ತಿಯನ್ನುಬಿಂಬಿಸುತ್ತವೆ.</p>
                                    <h4 className="tab-title">Proclamation Statement (ಉದ್ಘೋಷಣಾ ವಾಕ್ಯ )</h4>
                                    <p className="tab-desc">Na hi jnaanenasadrisham(ನ ಹಿ ಜ್ಞಾನೇನ ಸದೃಶಂ)</p>
                                    <h4 className="tab-title">Source (ಆಕರ)–Bhagavadgita( 4-38)</h4>
                                    <p className="tab-desc">ನ ಹಿ ಜ್ಞಾನೇನ ಸದೃಶಂ ಪವಿತ್ರಮಿಹ ವಿದ್ಯತೆ | <br />
                                        ತತ್‍ಸ್ವಯಂ ಯೋಗಸಂಸಿದ್ಧಃ ಕಾಲೇನಾತ್ಮನಿ ವಿಂದತಿ ||</p>

                                    <p className="tab-desc">Nothing exists in this world which is equal or comparable to knowledge as purifier. The one who is purified and competent through practice of Karma Yoga and Yoga of concentration that wins the self and gets the experience after the lapse of long time.</p>
                                    <p className="tab-desc">ಜ್ಞಾನಕ್ಕೆ ಸಮನಾದಅಥವಾ ಹೋಲಿಸಬಹುದಾದ ಪವಿತ್ರವಾದ ವಸ್ತುವು ಈ ಪ್ರಪಂಚದಲ್ಲಿ ಬೇರೆಯಾವುದೂಇಲ್ಲ. ಬಹಳ ಕಾಲದ ನಂತರಯಾವ ಪುರುಷನುಕರ್ಮಯೋಗ ಮತ್ತುಏಕಾಗ್ರತೆಯಿಂದಕೂಡಿದ ಯೋಗಗಳಿಂದ ಪವಿತ್ರನಾಗಿ, ಪರಿಶುದ್ಧವಾದ ಅಂತಃಕರಣವುಳ್ಳವನಾಗುವನೋ ಅಂತಹವನುಆತ್ಮನಲ್ಲಿಅನುಭವವನ್ನು ಪಡೆಯುತ್ತಾನೆ.</p>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}
export default Motos
