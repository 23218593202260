import React, { Component } from 'react';
import Datas from '../../data/gallery/gallery-page.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Styles } from './styles/gallery.js';
import Galleryfilter from '../../components/Galleryfilters';


class Gallery extends Component {
    
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Gallery" />
                  
                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                <Galleryfilter />
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    < Footer />

                </div>
            </Styles>
        )
    }
}

export default Gallery