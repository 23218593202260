import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class Activitydetails extends Component {

    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="4" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Programmes and Activities</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Assembly Programmes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i>Clubs</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> House System</Nav.Link>
                                        </Nav.Item>
                                       {/*  <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Col>
                                <Col lg="8" md="8" className='back1'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Programmes and Activities</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>All State and National Festivals</li>
                                                <li><i className="fa fa-check"></i>2.	Very important Jayantees</li>
                                                <li><i className="fa fa-check"></i>3.	Celebrations like Birthdays, Krishna Janmastami, VasanthaPanchami ,Rathasaptami, and Deepotsava are special events in the school.</li>
                                                <li><i className="fa fa-check"></i>4.	Another unique event is Aksharabhyasa for aspirant Kids.</li>
                                                <li><i className="fa fa-check"></i>5.	Significance of festivals during Local, State and National festivals</li>
                                                <li><i className="fa fa-check"></i>6.	Games and sport activities are also having equal importance on par with  curricular and co-curricular activities.</li>
                                                <li><i className="fa fa-check"></i>7.	School will also celebrate Environmental Day, Doctors Day, World Blood Donors Day, Engineers Day, Human Rights Day, Kargil Vijay Divas Day, National Youth Day, International Women’s Day, Science Day, International Yoga Day, etc., Along with curricular and co-curricular activities, Shastryschool encourages the students to participate in extra curricular activities.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Assembly Programmes</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Deepa mantra, Nada gite, National Anthem, SaraswathiVadana, Prayer, Meditation,
                                                    thought for the day, newspaper readings, demonstrationsof student talents, prize
                                                    distributions, birthday celebrations, general and specific announcements, skills,
                                                    etc.,
                                                </li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Clubs</h4>
                                            <p className="tab-desc">Clubs have big role in learning process – clubs provide ample opportunities to students those who have genuine interest and attitude towards certain subject or fields or art or hobby. These students will meet together, periodically and do certain related activities. In Shastry schools some of the following clubs will be organised:</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Science club/Science & Technology club</li>
                                                <li><i className="fa fa-check"></i>Mathematics club</li>
                                                <li><i className="fa fa-check"></i>Language club</li>
                                                <li><i className="fa fa-check"></i>Humanities club</li>
                                                <li><i className="fa fa-check"></i>Art & Craft club/Fine arts club</li>
                                                <li><i className="fa fa-check"></i>Dramatics club</li>
                                                <li><i className="fa fa-check"></i>Collection club</li>
                                                <li><i className="fa fa-check"></i>Commerce club</li>
                                                <li><i className="fa fa-check"></i>Electronics club</li>
                                                <li><i className="fa fa-check"></i>Eco club</li>
                                                <li><i className="fa fa-check"></i>Sports club , etc.,</li>
                                                
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">House System</h4>
                                            <p className="tab-desc">To develop the healthy spirit of competitions in Shastry Schools, in all parts of curriculum and co-curricular activities, House System will be introduced. This system guarantees personal attentions to each and every student of Shastry Schools. Four houses namely, Paila, Vaishampayana, Jaimini and Sumanta will start functioning from the beginning of the academic year. These houses will definitely bring out the innate talents and interests of students. These houses are common for all four categories of students namely Bala, Kishora, Proudha  andTaruna.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Service activities are centerd around in sensitizing the patriotic feelings and to bring the positive attitude towards the good Samskaras that make people happy and to stay with peace.</li>
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default Activitydetails
