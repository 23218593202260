import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import GoogleMap from './GoogleMap';
import Footer from '../../components/Footer';
import { Styles } from './styles/contact.js';

function Contact() {
    useEffect(() => {
        const form = document.getElementById("form_contact");
        const name = document.getElementById("contact_name");
        const email = document.getElementById("contact_email");
        const mobile = document.getElementById("mobile_no");
        const schoolname = document.getElementById("school_name");
        const message = document.getElementById("contact_message");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();
            const nameValue = name.value.trim();
            const emailValue = email.value.trim();
            const mobileValue = mobile.value.trim();
            const schoolValue = schoolname.value.trim();
            const messageValue = message.value.trim();

            const contacts  = {
                  name: nameValue,
                  email: emailValue,
                  mobile :mobileValue,
                  school : schoolValue,
                  message : messageValue
            }
            fetch('https://gsrgroup.biz/inventory_management/index.php/Sales_sms/send_contact_mail', {  // Enter your IP address here
                method: 'POST', 
                mode: 'cors', 
                body: JSON.stringify(contacts) // body data type must match "Content-Type" header

                }).then(
                    (result) => {
                        window.location.href = "/contact";
                        //if(result.data.status == 'success')
                        //{
                            //toastr.success(response.data.message);
                       // } else {
                            //toastr.error(response.data.message);
                        //}
                    }
                )
            // axios.post(`https://gsrgroup.biz/inventory_management/index.php/Sales_sms/send_contact_mail`, contacts).then(response => {
            //     if (response.data.status == 'success') {
            //         window.location.href = "/";
            //         //toastr.success(response.data.cls);
            //     } else {
            //         //toastr.error(response.data.message);
            //     }
            // });

                console.log(contacts);
            

            

            if (nameValue === "") {
                setError(name, "Name can't be blank");
            } else {
                setSuccess(name);
            }

            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
            } else {
                setSuccess(email);
            }



            if (mobileValue === "") {
                setError(mobile, "Mobile can't be blank");
            } else {
                setSuccess(mobile);
            }

            
            if (schoolValue === "") {
                setError(schoolname, "School Name can't be blank");
            } else {
                setSuccess(schoolname);
            }




            if (messageValue === "") {
                setError(message, "Message can't be blank");
            } else {
                setSuccess(message);
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }
    });

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Contact Us" />

                {/* Contact Area */}
                <section className="contact-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="contact-box-title">
                                    <h4>Contact Info</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-map-marker"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Our Location</h5>
                                        <p>B.M Bye Pass Road,Hunsur -571105, Mysore Dist,Karnataka.</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-envelope-open"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Email Address</h5>
                                        <p>shastryhunsur@gmail.com</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-phone"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Phone Number</h5>
                                        <p>+91  8050169541, 08222-297310</p>
                                    </div>
                                </div>
                                <div className="contact-social">
                                    <ul className="social list-unstyled list-inline">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Get In Touch</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form" >
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="text" placeholder="Full Name" id="contact_name"  />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="email" placeholder="Email Address" id="contact_email" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input type="number" min="0" placeholder="Mobile No" id="mobile_no" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">
                                                    <p className="form-control">
                                                       <select name="school_name" className="select" id="school_name">
                                                        <option value=""> Please Select School Name</option>
                                                        <option value="Shastry Kids Care (Day Care Centre)"> Shastry Kids Care (Day Care Centre)</option>
                                                        <option value="Shastry Kindergarten (State Wing)"> Shastry Kindergarten (State Wing)</option>
                                                        <option value="Shastry Kindergarten (CBSE Wing)"> Shastry Kindergarten (CBSE Wing)</option>
                                                        <option value="Shastry Primary School (State Wing)"> Shastry Primary School (State Wing)</option>
                                                        <option value="Shastry Primary School (Public School)"> Shastry Primary School (Public School)</option>
                                                        <option value="Shastry High School (State Wing)"> Shastry High School (State Wing)</option>
                                                        <option value="Shastry High School (Public School)"> Shastry High School (Public School)</option>
                                                        <option value="Shastry Composite Junior College (Non-Integrated)"> Shastry Composite Junior College (Non-Integrated)</option>
                                                        <option value="Shastry Composite Junior College (Integrated)"> Shastry Composite Junior College (Integrated)</option>
                                                        <option value="Shastry Composite Junior College (Commerce Stream)">Shastry Composite Junior College (Commerce Stream)</option>
                                                        <option value="Peer programme (VIII, IX, and X grade students)"> Peer programme (VIII, IX, and X grade students)</option>
                                                       </select>
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <p className="form-control">
                                                        <textarea name="message" id="contact_message" placeholder="Enter Message"></textarea>
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="12">
                                                    <button>Send Message</button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3898.056922531502!2d76.2950569!3d12.3119536!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba58521e7c51c39%3A0xd37aefb00bd156cc!2sShastry%20Public%20School%20%26%20Shastry%20Composite%20P%20U%20College%20Hunsur!5e0!3m2!1sen!2sin!4v1678708836689!5m2!1sen!2sin" width="1346" height="450" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <br/>
                            <br/>
                        </Row>
                    </Container>

                    {/* Google Map */}
                    {/* <GoogleMap /> */}
                    
                </section>

                {/* Footer 2 */}
                < Footer />

            </div>
        </Styles>
    )
}

export default Contact