import styled from "styled-components";

export const Styles = styled.div`
    .punchamuka-page {
        .icon-box-area {
            padding-bottom : 72px;
            .full-icon-box {
                .icon-box {
                    position: inherit;
                    top: 0;
                    left: 0;
                    width: unset;
                }
            }

            @media(max-width: 767px) {
                padding: 0 0 10px;
            }
        }
    }
    .readmore-btn {
        display:none !important
    }
    .about-para {
        text-align:justify
    }
    .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #10b477 !important;
    }
    .table-bordered td, .table-bordered th {
        border: 1px solid #00a76a;
    }
`;