import React, { Component } from 'react';
import Datas from '../data/footer/footer.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button} from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {
    render() {
        return (
            <Styles>
                {/* Footer Area */}
                <footer className="footer1" style={{ backgroundImage: `url(assets/images/${process.env.PUBLIC_URL + Datas.backgroundImage})` }}>
                    <Container>
                    <div id="mybutton">
                            <Button className="admin" style={{ marginLeft: "auto" }}>
                            <a href="https://wa.me/918050169541" style={{ color: "#fff" }}><i class="lab la-whatsapp"></i> Whatsapp</a>
                            </Button>
                            <br/>
                            <br/>
                            <Button className="admin" style={{ marginLeft: "auto" }}>
                               <Link  style={{ color: "#fff" }} to={process.env.PUBLIC_URL + "/contact"}> Admissions Open </Link>
                            </Button>
                        </div>
                        <Row>
                  
                            <Col md="4">
                                <div className="footer-logo-info">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/f-logo.png"} alt="" className="img-fluid" />
                                    <p>SCT is founded by Sri. SreekantaShastry and managed by noble minds,highly dedicated philanthropic educationists and administrators - Sri Radha Krishna S, Secretary, Sri Ravishankar M L, Principal, and Sri Sachith H S , Director. It is duly a registered body. It runs a group of schools, familiarly termed as Shastry Schools</p>
                                    
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-links">
                                    <h5>Useful Links</h5>
                                    <ul className="list-unstyled">
                                        <li><Link to={process.env.PUBLIC_URL + "/alumini"}><i className="las la-angle-right"></i>Alumni</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/digital-partner"}><i className="las la-angle-right"></i>Digital Partner</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/gallery"}><i className="las la-angle-right"></i>Gallery</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/downloads"}><i className="las la-angle-right"></i>Download</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/groups"}><i className="las la-angle-right"></i>Group of School</Link></li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li><Link to={process.env.PUBLIC_URL + "/facilities"}><i className="las la-angle-right"></i>Facilities</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/activities"}><i className="las la-angle-right"></i>Activities</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/404"}><i className="las la-angle-right"></i>Faculties</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/admissions"}><i className="las la-angle-right"></i>Admission</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/registrations"}><i className="las la-angle-right"></i>registration </Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-post">
                                    <h5>Contact</h5>
                                    <div className="post-box d-flex">
                                    <div className="footer-logo-info">
                                    <ul className="list-unstyled">
                                        <li><i className="las la-map-marker"></i>B.M Bye pass road,Hunsur-571105</li>
                                        <li><i className="las la-envelope"></i>shastryhunsur@gmail.com</li>
                                        <li><i className="las la-phone"></i>+91 8050169541, 08222-297310</li>
                                    </ul>
                                </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

                {/* Copyright Area */}
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="copy-text">
                                    <p>Copyright &copy; 2023 | Designed With <i className="las la-heart"></i> by <a href={process.env.PUBLIC_URL + "/"}  rel="noopener noreferrer">SHASTRY CHARITABLE TRUST (R)</a></p>
                                </div>
                            </Col>
                            <Col md="6" className="text-right">
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href="https://www.facebook.com/shastryschool?mibextid=ZbWKwL" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href="https://youtu.be/oQLM9D-LLbQ" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                    {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li> */}
                                    <li className="list-inline-item"><a href="https://instagram.com/shastryschool?igshid=YmMyMTA2M2Y=" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    {/* Back To Top */}
                    <BackToTop/>
                </section>
            </Styles>
        )
    }
}

export default Footer
