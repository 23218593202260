import React, { useEffect ,useState} from 'react';
import Datas from '../data/course/gallery.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/galleryfilter.js";
import ModalImage from "react-modal-image";

function Galleryfilters() {

    useEffect(() => {
        const buttons = document.querySelector(".filter-btn-list").children;
        const items = document.querySelector(".filter-items").children;

        for (let i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener("click", function () {

                for (let j = 0; j < buttons.length; j++) {
                    buttons[j].classList.remove("active");
                }

                this.classList.add("active");
                const target = this.getAttribute("data-target");

                for (let k = 0; k < items.length; k++) {
                    items[k].style.display = "none";

                    if (items[k].getAttribute("data-id") === target) {
                        items[k].style.display = "block";
                    }

                    if (target === "*") {
                        items[k].style.display = "block";
                    }
                }
            })
        }
    });
    const [users, setUsers] = useState([]);
    const fetchUserData = () => {
    fetch('https://shastryschool.com/backend/index.php/User_section/get_json_data')
    .then(response => response.json())
    .then((jsonData) => {
      // jsonData is parsed json object received from url
      //console.log(jsonData)
      setUsers(jsonData)
    })
    .catch((error) => {
      // handle your errors here
      console.error(error)
    })
      }
      useEffect(() => {
        fetchUserData()
      }, [])

      const [cats, setCats] = useState([]);
      const fetchCategory = () => {
        fetch('https://shastryschool.com/backend/index.php/User_section/get_json_groupdata')
        .then(response => response.json())
        .then((jsonData) => {
          // jsonData is parsed json object received from url
          console.log(jsonData)
          setCats(jsonData)
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
          }
          useEffect(() => {
            fetchCategory()
          }, [])


    
    return (
        <Styles>
            {/* Course Area */}
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="filter-btns text-center">
                           
                                {/* <ul>
                                {users.dataList.map((user, i) => (
                                    <li key={user.targetId}>Hello</li>
                                ))}
                                </ul> */}
                          
                                <ul className="filter-btn-list list-unstyled list inline">
                                    <li data-target="*" className="active list-inline-item">All</li>
                                    {
                                    cats.data?.map((data, i) => (
                                    <li data-target={data.targetId} className="list-inline-item">{data.groupname}</li>
                                    ))
                                }
                                </ul>
                            </div>
                            <br/>
                            <Row className="filter-items">
                                {
                                    users.dataList?.map((data, i) => (
                                        <Col lg="4" md="6" key={i} data-id={data.targetId}>
                                            <div className="course-item">
                                                <div className="author-img d-flex">
                                                    <div className="img">
                                                            <ModalImage small={data.authorImg} large={data.authorImg} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}
export default Galleryfilters
