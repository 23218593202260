import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class Downloads extends Component {
    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="4" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> A-GENERAL INFORMATION</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> DOCUMENTS AND INFORMATION</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i>RESULT AND ACDEMICS</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> SCHOOL APPLICATION</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> MANDATORY PUBLIC DISCLOSURE</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg="8" md="8" className='back1'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">A-GENERAL INFORMATION</h4>
                                            <Row>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link  to={process.env.PUBLIC_URL + "/assets/images/docs/A CENERAL INFORMATION.DOCX"} target="_blank" download><i className="las la-clipboard-list"></i>General Information</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">DOCUMENTS AND INFORMATION</h4>
                                            <Row>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Affilation.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Affilation</Link>
                                                    </div>
                                                </Col>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Trust Deed.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Trust Deed</Link>
                                                    </div>
                                                </Col>
                                                <Col  md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/No Objction.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>No Objction</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                
                                                {/* <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/admissions"}><i className="las la-clipboard-list"></i>Recogniton Certificate under RTE</Link>
                                                    </div>
                                                </Col> */}
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Building Safety.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Building Safety</Link>
                                                    </div>
                                                </Col>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Fire Safety.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Fire Safety</Link>
                                                    </div>
                                                </Col>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Self Certification.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Self Certification</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Water Safety.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Water Safety</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">RESULT AND ACDEMICS</h4>
                                            <Row>
                                                <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/result/FEES.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Fees</Link>
                                                    </div>
                                                </Col>
                                                <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/result/Academic Calander.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Academic Calander</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/result/Committee Details.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Committee Details</Link>
                                                    </div>
                                                </Col>
                                                <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/result/Parents Teachers Association.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Parents Teachers Association</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/result/Last 3 Years Results.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Last 3 Years Results</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">SCHOOL APPLICATION</h4>
                                            <Row>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/application/CBSE Application.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>CBSE Application</Link>
                                                    </div>
                                                </Col>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/application/I PUC Application.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>I PUC Application</Link>
                                                    </div>
                                                </Col>
                                                <Col md="4" lg="4">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/application/State Application.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>State Application</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="research">
                                            <h4 className="tab-title">MANDATORY PUBLIC DISCLOSURE</h4>
                                            <Row>
                                                <Col md="6" lg="6">
                                                    <div className="apply-btn">
                                                        <Link to={process.env.PUBLIC_URL + "/assets/images/docs/Mandatory Public Disclosure APPENDIX -IX.pdf"} target="_blank" download><i className="las la-clipboard-list"></i>Mandatory Public Disclosure</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default Downloads
