import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {
    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Mile stones</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Bench Mark</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i>Near Future Plans </Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Our Ranking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Mile stones</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>The humble beginning of State pattern Shastry High school was in the year 1994, in the rental building with 52 students of VIII standard and 6 teachers. At the first time in Hunsur, experiment was started with the introduction of  computer science as one subject when no schools in Mysore district, introduced at the school level.</li>
                                                <li><i className="fa fa-check"></i>The first batch of S.S.L.C students successfully came out from Shastry High School with colourful excellent feathers in the year 1996.</li>
                                                <li><i className="fa fa-check"></i>The School is shifted to tranquil and serene environment, ideally suited for students to pursuit academic and human excellence, in the Year 1998</li>
                                                <li><i className="fa fa-check"></i>State level KG classes started in the year 1996</li>
                                                <li><i className="fa fa-check"></i>First batch of outgoing SSLC students – in 1996</li>
                                                <li><i className="fa fa-check"></i>First batch of outgoing PU students - 2004</li>
                                                <li><i className="fa fa-check"></i>CBSE first standard started in the year – 2009</li>
                                                <li><i className="fa fa-check"></i>CBSE Kinder Garten started in the year – 2013</li>
                                                <li><i className="fa fa-check"></i>Scouts and Guides are introduced in the year - 2013</li>
                                                <li><i className="fa fa-check"></i>First batch of CBSE outgoing students – 2017</li>
                                                <li><i className="fa fa-check"></i>Peer group programme started in the year – 2020</li>
                                                <li><i className="fa fa-check"></i>PU Commerce stream started in the year – 2020</li>
                                                <li><i className="fa fa-check"></i>PU Integrated Science Scheme started in the year - 2021</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Bench Mark</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>The products of Shastry Schools have become Nation building sculptors. Most of them are working in the reputed institutions of the country and abroad.</li>
                                                <li><i className="fa fa-check"></i>Many students of Shastry Schools performed well in AIEEE or JEE, CET, NEET, GRA, TOEFL, etc., From the past many years, they are grabbing seats in reputed Engineering Colleges and Medical Colleges. Most of them working as doctors, engineers, technicians, lawyers,etc., </li>
                                                <li><i className="fa fa-check"></i>Ever since the inception, Shastry schools enjoying outstanding results and distinctions. So far it has produced 26 successful batches of SSLC students.</li>
                                                <li><i className="fa fa-check"></i>Pass percentage of students either at the State Board of at yheCentral Board of Secondary Education Level is 90 to 99 percent with many distinctions.</li>
                                                <li><i className="fa fa-check"></i>Pass percentage of  PU students is also 90 to 99 percent with many distinctions</li>
                                                <li><i className="fa fa-check"></i>Shastry school students participated ,showcasedtheir talents  and won prizes in International, National, State and Districts levels.</li>
                                                <li><i className="fa fa-check"></i>Students of Shastryschool participated even at the National Level Sport competitions. They won prizes at the School level, Taluk level, District level, State level and Zonal level, several timesand for several years.</li>
                                                <li><i className="fa fa-check"></i>School encourages march past teams and band set teams. Students in these teams got award as the best march past teams at the National level, during Independence Day Republic Day events.</li>
                                                <li><i className="fa fa-check"></i>Scouts attended International Cultural Jamboorie, in Mudubidre.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Near Future Plans </h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Hostel facility will be provided for the aspirants from class IX onwards</li>
                                                <li><i className="fa fa-check"></i>To cherish the pride and to keep prestige of Shastry schools ‘Alma Mater’ Alumni association will be shortly constituted.</li>
                                                <li><i className="fa fa-check"></i>New building will be utilised as soon as the construction work is completed.</li>
                                                <li><i className="fa fa-check"></i>Mathematics Laboratory, Social Science Laboratory, Tinkering Laboratory, Language cum SanatanaSamkriti Kendra etc., will be developed</li>
                                                <li><i className="fa fa-check"></i>SamskaraKendras for interested public.</li>
                                                <li><i className="fa fa-check"></i>Personality development programmes for the school going children, college students and youths.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">Our Ranking</h4>
                                            <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="research">
                                            <h4 className="tab-title">Our Research</h4>
                                            <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                                <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TabBox
