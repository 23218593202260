import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class Faciality extends Component {
    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="4" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i>Class Rooms</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i>Laboratories</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i>3D - Audiotorium</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Library</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Computer Laboratory</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="games"><i className="las la-arrow-right"></i> Play Ground</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no7"><i className="las la-arrow-right"></i> Scouts and Guides</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no8"><i className="las la-arrow-right"></i> Karate</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no9"><i className="las la-arrow-right"></i> Yoga</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no10"><i className="las la-arrow-right"></i> Jolly Phonics</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no11"><i className="las la-arrow-right"></i> Next Education Smart Class</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no12"><i className="las la-arrow-right"></i> Power of EMBIBE</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no13"><i className="las la-arrow-right"></i> My Class Board (MCB)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no14"><i className="las la-arrow-right"></i> ROUV-Water Purifier</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no15"><i className="las la-arrow-right"></i> SMS and Wi-fi Facility</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no16"><i className="las la-arrow-right"></i> Transportation</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no17"><i className="las la-arrow-right"></i> Music Room</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no18"><i className="las la-arrow-right"></i> Play Station</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no19"><i className="las la-arrow-right"></i>Other facilities</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="no20"><i className="las la-arrow-right"></i> VidyaBharathi Programmes</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg="8" md="8" >
                                    <Tab.Content className='back1'>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Class Rooms</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/classroom.png`} alt="" className="detail_img"/>
                                            <p className="tab-desc">Spacious 50+ rooms in three buildings are equipped with digital boards, smart boards and other boards. These rooms provide interactive learning platformsto students. Even students get hands on experiences when they use these boards, along with teachers. Size of the boards are even suitable at Pre-University level, also. Walls will speak at the Foundational stage. Kids classrooms are quintessentially idealistic. </p>
                                            <p className="tab-desc">School has taken more care for children comfort by providing amenities like Kid Corner (Play way teaching) and Doll Corner (equipped with dolls, play materials and learning materials). Class rooms are technologically upgraded. Handmade puppets and colourful books are also being used in these rooms.  A few more learning materials will be added in the coming years. Even at present, Kids rooms appear like amusement parks tucked within four walls and give great joy. These rooms are memorable to kids, forever.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Laboratories</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/lab.png`} alt="" className="detail_img"/>
                                            <p className="tab-desc">Laboratories are spacious, adequately equipped and student-friendly in Shastry schools. From IX standard to Second Year Pre-University students are using Physics laboratory, Chemistry laboratory, Biology laboratory and Mathematics laboratory. These laboratories will be further equipped to make use of these materials from VI standard onwards.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">3D - Audiotorium</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/auditoriam.png`} alt="" className="detail_img"/>
                                            <p className="tab-desc">It is not just a hall with concrete walls. It is the place to get several experiences in life. It is the Silence Zone. It is the Meditation Hall. It is the Prayer Hall. It is the Yoga Hall. It is a Serene Hall in Shastry schools.It is being used for various purposes. To organise workshops, orientation programmes, to heighten the minds of parents towards spirituality, etc.,</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking">
                                            <h4 className="tab-title">Library</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/library.png`} alt="" className="detail_img"/>
                                            <p className="tab-desc">Qualified librarian looks after library. It has about 4000 volumes, periodicals, magazines and specimen copies of various publications that are very much useful to students. </p>
                                            <p className="tab-desc">Students are using the library during their leisure time. Even library periods are allotted in the time table. During library periods, students make use of the library.Library is inbuilt in Pre-Primary level 1 to 5th standard.Children can make use of the books whenever they want.</p>
                                            <p className="tab-desc">Children library is set up in a few Kinder Garten classes. It will be furnished from the Pre-Primary Level 1 to V standard. Children can make use of class room library at any time in a day. Students and teachers have free access to this library. These serveas reference materials for everyone. E-library is inbuilt in digital boards of class rooms.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="research">
                                            <h4 className="tab-title">Computer Laboratory</h4>
                                            <p className="tab-desc">Trained teachers look after computers. To cater the learning needs of students computer laboratories are well equipped with latest softwares. In 1:1 ratio, students can use computers in Shastry schools.</p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="games">
                                            <h4 className="tab-title">Play Ground- Games and Sports</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/ground.png`} alt="" className="detail_img"/>
                                            <p className="tab-desc">Sufficient play fields help students to play many games. All students are involved either in outdoor games or in indoor games.</p>
                                            <h4 className="tab-title">Outdoor Games</h4>
                                            <p className="tab-desc">Students learn the skills of </p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Basket Ball</li>
                                                <li><i className="fa fa-check"></i>Volleyball</li>
                                                <li><i className="fa fa-check"></i>Throwball</li>
                                                <li><i className="fa fa-check"></i>Kho-Kho</li>
                                                <li><i className="fa fa-check"></i>Cricket, etc.,</li>
                                            </ul>
                                            <br/>
                                            <h4 className="tab-title">Indoor Games</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/indoor.png`} alt="" className="detail_img"/>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Carrom</li>
                                                <li><i className="fa fa-check"></i>Chess</li>
                                                <li><i className="fa fa-check"></i>Shuttle Cock in four courts</li>
                                                <li><i className="fa fa-check"></i>Table tennis, etc.,</li>
                                            </ul>
                                            <br/>
                                            <h4 className="tab-title">Traditional Games will be played by students</h4>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Kabbadi</li>
                                                <li><i className="fa fa-check"></i>Lagori</li>
                                                <li><i className="fa fa-check"></i>Marbles</li>
                                                <li><i className="fa fa-check"></i>Chain Game</li>
                                                <li><i className="fa fa-check"></i>Chinni-Dandu/Gilli-dandu</li>
                                                <li><i className="fa fa-check"></i>Dog and the bone, etc.,</li>
                                            </ul>
                                            <br/>
                                            <h4 className="tab-title">Recreational  Games</h4>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Kangaroo relay</li>
                                                <li><i className="fa fa-check"></i>Passing Voice</li>
                                                <li><i className="fa fa-check"></i>Chennemane/Pallanguli</li>
                                                <li><i className="fa fa-check"></i>Game of Dice</li>
                                                <li><i className="fa fa-check"></i>Snake and ladder etc</li>
                                            </ul>
                                           
                                            <p className="tab-desc">March Past – formations during important days.</p>
                                            <br/>
                                            <h4 className="tab-title">Mass PT Exercises</h4>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Standing mass drills</li>
                                                <li><i className="fa fa-check"></i>Sitting mass drills</li>
                                                <li><i className="fa fa-check"></i>Clapping mass drill</li>
                                                <li><i className="fa fa-check"></i>Hoops drill etc.,</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no7">
                                            <h4 className="tab-title">Scouts and Guides</h4>
                                            <p className="tab-desc">In 2013 Guides and Scouts are introduced in the school. Many scouts activities are going on. Students attended Jamboories</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no8">
                                            <h4 className="tab-title">Karate</h4>
                                            <p className="tab-desc">Trained Karate teacher takes classes. There are three divisions – Basic, Katas and Kumvu(fighting). Even students get practice in physical exercises.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no9">
                                            <h4 className="tab-title">Yoga</h4>
                                            <img src={process.env.PUBLIC_URL + `/assets/images/faciality/yoga.png`} alt="" className="detail_img"/>
                                            <p className="tab-desc">Age appropriate yogasanas will be taught to students. A few steps of Patanjali’sAstanga Yoga isbeing introduced in higher classes. Aasanas are being done through activities, also. Surya namasakara,  BasicAasanas, Prayer and Meditation are being practiced.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no10">
                                            <h4 className="tab-title">Jolly Phonics</h4>
                                            <p className="tab-desc">Jolly Phonics will be introduced compulsorily from Pre-Primary 1 to Class 2 level. Now teachers are trained in these interesting skills. 25 sounds will be introduced at the Pre-Primary 1 level. Continuation of 26 sounds and 13 tricky words will be introduced at Pre-Primary level 2. Continuation of 26 sounds plus 16 sounds and 42 tricky words will be introduced at Pre-primary level 3. Children will learn, reading, comprehension and telling short stories. Age appropriate activity and application activities will be done at the  first and second standards.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no11">
                                            <h4 className="tab-title">Next Education Smart Class</h4>
                                            <p className="tab-desc">Class room child Friendly digital boards have concept centered lessons, related videos, objective type questions, assignments, </p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no12">
                                            <h4 className="tab-title">Power of EMBIBE</h4>
                                            <p className="tab-desc">The school has the power of Embibe. It has innovative virtual laboratory, text books, books written by several authors, videos, resource materials, daily assessment, drill work materials. This is being used by teachers.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no13">
                                            <h4 className="tab-title">My Class Board (MCB) </h4>
                                            <p className="tab-desc">Reverse-Osmosis Ultra Violet water purifier is installed in the school campus. Students drink purified water. Students of Shastry School more quite healthy, in general, due to non-consumption of contaminated water.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no14">
                                            <h4 className="tab-title">ROUV-Water Purifier </h4>
                                            <p className="tab-desc">It is the software specially prepared for school. It is school friendly and parent friendly. Parents can install their app in mobile through play store. All school related activities, payment of fees, reports, academic activities, GPS track for transportation, etc., can be easily accessible by parents.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no15">
                                            <h4 className="tab-title">SMS and Wi-fi Facility</h4>
                                            <p className="tab-desc">Teachers and office staff are utilising SMS andWi-fifacility. </p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no16">
                                            <h4 className="tab-title">Transportation</h4>
                                            <p className="tab-desc">To cater the needs of students and parents school provides transport facilities at reasonable fares. Buses are well maintained. Seven buses take safety of all students those who are utilising the transportation services and fly in and around Hunsur.Students from the following places are utilising the transportation services: </p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Hunsur Town</li>
                                                <li><i className="fa fa-check"></i>H D Kote Route</li>
                                                <li><i className="fa fa-check"></i>K R Nagar</li>
                                                <li><i className="fa fa-check"></i>Hanagodu Area</li>
                                                <li><i className="fa fa-check"></i>Periyapatna</li>
                                                <li><i className="fa fa-check"></i>Panchavalli</li>
                                                <li><i className="fa fa-check"></i>Bannikuppe</li>
                                                <li><i className="fa fa-check"></i>Gavadagere</li>
                                                <li><i className="fa fa-check"></i>Bilikere</li>
                                                <li><i className="fa fa-check"></i>Gaddige</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no17">
                                            <h4 className="tab-title">Music Room</h4>
                                            <p className="tab-desc">Music Room is equipped with musical instruments. Even students learn western music from the qualified teacher.</p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no18">
                                            <h4 className="tab-title">Play Station</h4>
                                            <p className="tab-desc">Spacious play station meant for Kinder Garten children is totally equipped with all playground equipments such as swings, slopes, etc., </p>
                                            
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no19">
                                            <h4 className="tab-title">Other facilities</h4>
                                           
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>First Aid Facility</li>
                                                <li><i className="fa fa-check"></i>Hygienic Rest Rooms</li>
                                                <li><i className="fa fa-check"></i>Psychological Counselling by experts</li>
                                                <li><i className="fa fa-check"></i>Safety Vigiliance – CC camera Surveillance everywhere in the campus.</li>
                                                <li><i className="fa fa-check"></i>Security</li>
                                                <li><i className="fa fa-check"></i>Communicative English Classes</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="no20">
                                            <h4 className="tab-title">VidyaBharathi Programmes</h4>
                                           
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Shaikshanika Sahamilana</li>
                                                <li><i className="fa fa-check"></i>Sport  Competitions (State and National Level)</li>
                                                <li><i className="fa fa-check"></i>PrashiksahanaVarga (Training for subject teachers)</li>
                                                <li><i className="fa fa-check"></i>ShishuShikashanaShibira( Work shop for KG Teachers for 10 days)</li>
                                                <li><i className="fa fa-check"></i>VidyaVikasaShibira( Work shop for all Teachers except KG teachers for 10 days)</li>
                                                <li><i className="fa fa-check"></i>Age appropriate Parent Teacher Meetings</li>
                                                <li><i className="fa fa-check"></i>National Level Programmes – Science Exhibitions, Quiz , Katha kathana, etc.,</li>
                                                <li><i className="fa fa-check"></i>Programmes in SamskaraKendras</li>
                                                <li><i className="fa fa-check"></i>MaathruBharati Programmes (for Mothers)</li>
                                                <li><i className="fa fa-check"></i>PracharyaVarga Programmes</li>
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default Faciality
