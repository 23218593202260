import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class Practicedetails extends Component {

    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Methods of Teaching </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Best Practices </Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Our Ranking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content className='back1'>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Methods of Teaching – Learning Processes</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Shastry schools follow the techniques of experiential learning based on self-centred principles of learning such as self-activity, self-pace, self-experience, self-involvement in the group, self-responsibility, self-construction of knowledge, self-learning through sensory organs and perceptory organs and self-evaluation. Therefore, learning becomes joyful,innovative techniques, creative techniques, integrated techniques, and project based learning make a shift from traditional techniques of teaching to modern techniques. Experimentations, laboratory method, demonstration method, problem solving method, assignment method, guided discovery method, activity method, play-way method etc., will also take prominent places in the method of teaching – learning process.</li>
                                                <li><i className="fa fa-check"></i>To facilitate stress free acceleration to learning process,Continuous Comprehension Evaluation system is followed and it is in pace with the trends of modern knowledge society. Less weightage will be given to marking scheme. Assessments will be done qualitatively as soon as National Education Policy is introduced.</li>
                                                <li><i className="fa fa-check"></i>The present pattern of tests and examinations will be continued till the implementation of NEP-2020. However, students profile will be maintained. Group discussions, seminars, paper presentations, project work, skill based work, etc., will be included while evaluating the students learning process. The formats of progress reports may also change from time to time as per guidelines and methods prescribed by boards.</li>
                                            
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Best Practices</h4>
                                            {/* <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p> */}
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Member of Management, expert teachers and staff members help students of Shastry schools in all round development of personality. They guide, counsel and motivate right from the day of joining to Shastry schools. So, many practices are best, in Shastry schools.</li>
                                                <li><i className="fa fa-check"></i>Students clear their doubts as and when they get because they are free to access to any teacher in leisure times.</li>
                                                <li><i className="fa fa-check"></i>Opportunities will be provided to children to learn four languages – English, Kannada, Sanskirit and Hindi from the Pre-primary level.</li>
                                                <li><i className="fa fa-check"></i>All activities and programmes are central around developing 21stcentuary skills. </li>
                                                <li><i className="fa fa-check"></i>Life skills, values, arts & crafts, physical education, different disciplines of knowledge will be integrated in curricular and co-curricular programmes.</li>
                                                <li><i className="fa fa-check"></i>Parent Teacher Meetings (PTM’s) are invariably begin from prayer. Lecturer cum guidance sessions arranged for parents will help their wards to guide at home.</li>
                                                <li><i className="fa fa-check"></i>Major stress is given to develop adequate competencies in school subjects.</li>
                                                <li><i className="fa fa-check"></i>Lot of care is taken to develop the spirit of patriotism among students.</li>
                                                <li><i className="fa fa-check"></i>Learner centred joyful approaches are followed by teachers in classrooms.</li>
                                                <li><i className="fa fa-check"></i>Teachers are being trained, continuously to make students as knowledge practitioners.</li>
                                                <li><i className="fa fa-check"></i>To make things happen by encouraging students endowed with talents to acquire skills.</li>
                                                <li><i className="fa fa-check"></i>To reinforce assimilation and creative utilisation of information to solve problems in day to day life.</li>
                                                <li><i className="fa fa-check"></i>To reduce the stress of students at the time of term end examinations by organising guidance classes from the beginning of the academic year.</li>
                                                <li><i className="fa fa-check"></i>To help students of Minimum Level Learners, Average Level Learners, Achiever Level Learners, teachers will be trained, continuously.</li>
                                                <li><i className="fa fa-check"></i>To see that all activities of school should become beneficial to students.</li>
                                                <li><i className="fa fa-check"></i>To prepare individuals ‘For Life through Life’ life building activities are being organised. For this purpose, SCT is striving to its level best to blend traditional ideas and cultural components of life with the present technological set up in the holistic perspective. </li>
                                                <li><i className="fa fa-check"></i>Promotion to use Swadeshi Products to make country as economically strong.</li>
                                                <li><i className="fa fa-check"></i>Students’ self-government to provide leadership training.</li>
                                                <li><i className="fa fa-check"></i>Cursive handwriting is emphasized at the foundational level.</li>
                                                <li><i className="fa fa-check"></i>Lot of care has taken to develop communicative skills, effectively among students.</li>
                                                <li><i className="fa fa-check"></i>Motivational classes for personality development, environmental awareness, etc., are periodically organised.</li>
                                                <li><i className="fa fa-check"></i>‘Peer Programmes’ from class VIII onwards, helping students to make fit for competitive examinations.</li>
                                                <li><i className="fa fa-check"></i>Semi-residential facilities will be provided to students.</li>
                                                <li><i className="fa fa-check"></i>Milk and snacks will be provided to Kids in the ‘Day Care’.</li>
                                                <li><i className="fa fa-check"></i>Lunch is provided to those who opt for it. </li>
                                                <li><i className="fa fa-check"></i>Students voluntarily participate even in extra curricular activities.</li>
                                                <li><i className="fa fa-check"></i>Educational activities run in unison with the cooperation of many groups working together such as teachers & students, teachers & parents, management & parents, management & public, etc.,</li>
                                                
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default Practicedetails
